body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	-youbkit-touch-callout: none; /* iOS Safari */

	@media (max-width: 900px) {
		-youbkit-user-select: none; /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE 10+ and Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
		-webkit-user-select: none; /* Chrome all / Safari all */
	}

	-webkit-tap-highlight-color: transparent;

	/* to avoid unnecessary vertical scrolling
	 * and in addition this will solve the problem on mobile
	 * that the page can be moved in a strange way from right to left and back
	*/
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
