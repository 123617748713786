/*.react-tiger-transition--screen {
	overflow-y: auto !important;
}*/
/* Test comment */

body {
	/*to disable pinch zoom on iOS 12.x*/
	/*touch-action: pan-y;*/
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

a {
	text-decoration: none;
	color: white;
}

//::-webkit-scrollbar {
// -webkit-appearance: none;
//	width: 10px;
//}
//
//::-webkit-scrollbar-thumb {
//	background-color: rgba(0,0,0,.4);
//	border-radius: 10px;
//}

.tooltip {
	font-size: 12px !important;
	max-width: 200px;
	padding: 8px !important;
}

.paymentMessage {
	&.root {
		// text-align: center;
		// background-color: #d9edf7;
		// border-color: white;
		// border-style: dashed;
		padding: 10px;
		color: white;
	}
}

// for google places - showing address suggestions when typing in
// z-index is necessary when using in a modal
.pac-container {
	z-index: 999999999;
	margin-top: 12px;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes mobile-menu-appears {
	0% {
		left: -280px;
	}
	100% {
		left: 0;
	}
}

@keyframes mobile-menu-disappears {
	0% {
		left: 0;
	}
	100% {
		left: -280px;
	}
}

@keyframes shaking-with-interval {
	0% {
		transform: translate(0px, 0px) rotate(0deg);
	}
	80% {
		transform: translate(0px, 0px) rotate(0deg);
	}
	82% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	84% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	86% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	88% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	90% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	92% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	94% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	96% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	98% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}

/* This approximates the ease-in-out-bounce animation from easings.net, which would require a plug-in to use*/
@keyframes kiosk-error-bounce {
	0% {
		transform: translateX(0px);
		timing-function: ease-in;
	}
	37% {
		transform: translateX(5px);
		timing-function: ease-out;
	}
	55% {
		transform: translateX(-5px);
		timing-function: ease-in;
	}
	73% {
		transform: translateX(4px);
		timing-function: ease-out;
	}
	82% {
		transform: translateX(-4px);
		timing-function: ease-in;
	}
	91% {
		transform: translateX(2px);
		timing-function: ease-out;
	}
	96% {
		transform: translateX(-2px);
		timing-function: ease-in;
	}
	100% {
		transform: translateX(0px);
		timing-function: ease-in;
	}
}

.smartbanner {
	z-index: 100 !important;
}
